<template>
    <BContainer fluid>
        <h1 class="text-center">{{projects.slide1[0].projectName}}</h1>

        <BRow class="justify-content-center mt-4">
            <div class="col-12 col-xl-6">
                <BImg
                    class="image"
                    :src="projects.slide1[0].websiteImageUrl"
                    fluid
                />
            </div>

            <div class="col-12 col-xl-6">
                <h4 class="d-none d-md-block">
                    The Stack
                </h4>

                <div class="mt-md-0 mt-2 text-center text-md-left">
                    <b-badge class="vue-badge">
                        <FontAwesomeIcon
                            :icon="[ 'fab', 'vuejs' ]"
                            class="fa-2x"
                        />
                        Vue
                    </b-badge>

                    <b-badge class="ml-2 graphql-badge">
                        <FontAwesomeIcon
                            :icon="[ 'fas', 'project-diagram' ]"
                            class="fa-2x"
                        />
                        GraphQL
                    </b-badge>

                    <b-badge class="ml-2 bootstrap-badge">
                        <FontAwesomeIcon
                            :icon="[ 'fab', 'bootstrap' ]"
                            class="fa-2x"
                        />
                        Bootstrap
                    </b-badge>

                    <b-badge class="ml-2 mt-2 mt-md-0 sass-badge">
                        <FontAwesomeIcon
                            :icon="[ 'fab', 'sass' ]"
                            class="fa-2x"
                        />
                        Sass
                    </b-badge>
                </div>

                <h4 class="d-none d-md-block mt-4">
                    About
                </h4>

                <p class="mt-md-0 mt-3">
                    Zauvu is a project I have been working on with a group of friends from college. The goal of this website 
                    is to combine all aspects of travel planning into one application. This includes managing hotels, flights, 
                    transportation, and itineraries while providing an environment for trip member collaboration.
                </p>

                <p>
                    My primary responsibility with the project is developing the frontend and utilizing the API developed by our 
                    backend engineer. This creates a unique collaboration environment where I am not able to modify the backend 
                    on the fly. This has made it so that both of us need to be clear with our objectives and strategic with how 
                    the data is managed.
                </p>

                <p>
                    Zauvu is by far the largest personal project that I have undertaken, and it includes 11 views, more than 50 
                    components, and tens of thousands of lines of code. This has truly been a test of my ability to think through 
                    which sections of code should be made to be reusable. I have made a lot of mistakes along the way, but I credit 
                    them with greatly enhancing my development skills.
                </p>

                <p>
                    Zauvu is currently in its beta version, and you can sign up to be a part of it below. Feel free to send me an email 
                    if you do, we're always looking to collect feedback and optimize the user experience.
                </p>
                
                <a
                    href="https://www.zauvu.com/beta-signup"
                    target="_blank"
                >
                    <b-button
                        variant="primary"
                    >
                        Zauvu Beta Sign Up
                    </b-button>
                </a>
            </div>
        </BRow>

        <ProjectNav
            :no-previous-nav="true"
            :next-project="'September Thomas'"
            :website-url="projects.slide1[0].url"
        />
    </BContainer>
</template>

<script>
    import Vue from 'vue';
    import {mapState} from 'vuex';

    import ProjectNav from '@/components/ProjectNav';

    import {library} from '@fortawesome/fontawesome-svg-core';
    import {
        faBootstrap,
        faSass,
        faVuejs,
    } from '@fortawesome/free-brands-svg-icons';
    import {
        faProjectDiagram
    } from '@fortawesome/free-solid-svg-icons';
    import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

    library.add(
        faBootstrap,
        faProjectDiagram,
        faSass,
        faVuejs,
    );

    Vue.component('FontAwesomeIcon', FontAwesomeIcon);

    export default {
        name: 'Zauvu',
        components: {
            ProjectNav,
        },
        computed: {
            ...mapState(['projects'])
        }
    }
</script>

<style
    lang="sass"
    scoped
>
    @import "/src/custom"

    .fa-4x
        color: $primary-green
        &:hover
            color: $secondary-green

    .image
        border-radius: 10px
</style>
